<template>
  <div>
    <NForm :model="model" @submit.prevent.stop="login">
      <NFormItem label="Email" path="email" :feedback="errors.email" :validation-status="errors.email ? 'error' : 'success'">
        <NInput v-model:value="model.email" @keydown.enter.prevent/>
      </NFormItem>

      <NFormItem first label="Password" path="password" :feedback="errors.password" :validation-status="errors.password ? 'error' : 'success'">
        <NInput v-model:value="model.password" @keydown.enter.prevent/>
      </NFormItem>

      <NFormItem>
        <NCheckbox v-model:checked="model.remember">Remember Me</NCheckbox>
      </NFormItem>


      <NButton :loading="isLoading" attr-type="submit" type="primary">
        <template #icon>
          <i class="bi bi-box-arrow-in-right"/>
        </template>
        <span>Login</span>
      </NButton>
    </NForm>

    <div class="d-flex flex-column gap-2 mt-4">
      <div class="text-center">Don’t have an account yet?
        <RouterLink :to="{name: 'Register'}" v-text="'Join Now'"/>
      </div>
      <div class="text-center">
        <RouterLink :to="{name: 'ForgotPassword'}" v-text="'Having trouble signing in?'"/>
      </div>
    </div>
  </div>
</template>

<script>
import useAsyncCallback from '@core/composable/useAsyncCallback.ts';
import useValidationErrors from '@core/composable/useValidationErrors.ts';
import axios, {AxiosError} from 'axios';
import {NAlert, NButton, NCheckbox, NForm, NFormItem, NInput, NSpin, useMessage} from 'naive-ui';
import {reactive} from 'vue';

export default {
  name: 'Login',
  components: {
    NSpin,
    NAlert,
    NButton,
    NInput,
    NForm,
    NFormItem,
    NCheckbox,
  },
  setup() {
    const notification = useMessage();

    const model = reactive({
      email: '',
      password: '',
      remember: false,
    });

    const {errors, clearError, fromAxiosError} = useValidationErrors(['email', 'password']);

    const {isLoading, execute: login} = useAsyncCallback(async () => {
      clearError?.();

      try {
        const {data: {message, redirect}} = await axios.request({
          method: 'POST',
          data: model,
          url: '/login',
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
          },
        });

        notification.success(message);

        if (redirect) {
          window.location = redirect;
        }
      } catch (e) {
        fromAxiosError?.(e);

        if (e instanceof AxiosError) {
          const {message: msg, redirect} = e.response.data ?? {};

          notification.errors(msg);

          if (redirect) {
            window.location = redirect;
          }
        }
      }
    });

    return {
      model,
      errors,
      login,
      isLoading,
    };
  },
};
</script>

<style lang="scss" scoped>
.es-bg-secondary {
  background-color: #f1f1f1;
}

.btn-primary-es {
  background: #3152a3;
  border-color: #3152a3;
}
</style>
