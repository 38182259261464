<template>
  <div class="space-y-3.5">
    <Logo :ratio="0.45" :version="2" class="w-full flex justify-center" color-blend/>
    <NCollapseTransition :show="!!message">
      <div
          class="px-4 py-3 bg-gray-800/90 text-green-300 flex items-center border-none rounded-sm shadow leading-4 text-sm font-medium">
        <div v-if="!!message"
             class="alert alert-primary border-0 rounded-0" role="alert" v-text="message">
        </div>
      </div>
    </NCollapseTransition>
    <div class="flex flex-col bg-white rounded shadow-md" style="min-width: min(28rem, calc(100vw - 2rem))">
      <h1 class="text-xl font-semibold px-6 pt-6 pb-2 leading-tight tracking-tight md:text-2xl text-gray-900">Reset your
        account password</h1>
      <ResetPasswordForm
          class="flex flex-col px-6 border-slate-200/50 border-b"
          @saved="(res)=> resp=res"
      />
    </div>
  </div>
</template>

<script setup lang="tsx">
import Logo from "@core/components/Logo.vue";
import {NCollapseTransition} from "naive-ui";
import {computed, ref} from "vue";
import ResetPasswordForm from "@guest/components/ResetPasswordForm.vue";

const resp = ref<any>();
const message = computed(() => {
  return resp.value?.message;
});

</script>

<style scoped lang="scss">

</style>