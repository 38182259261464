<template>
  <div class="space-y-3.5">
    <Logo :ratio="0.45" :version="2" class="w-full flex justify-center" color-blend/>

    <NCollapseTransition :show="showMessage">
      <div
        class="px-4 py-3 bg-gray-800/90 text-green-300 flex items-center border-none rounded-sm shadow leading-4 text-sm font-medium">
        <CheckedCircleFilledIcon class="inline text-green-400 mr-1" width="18"/>
        <span v-text="message"/>
      </div>
    </NCollapseTransition>

    <div class="flex flex-col bg-white rounded shadow-md" style="min-width: min(28rem, calc(100vw - 2rem))">
      <h1 class="text-xl font-semibold px-6 pt-6 pb-2 leading-tight tracking-tight md:text-2xl text-gray-900">Reset your
        account password</h1>
      <div class="flex flex-col px-6 py-4 border-slate-200/50 border-b">
        <NForm :model="model" class="flex flex-col space-y-3" @submit.prevent.stop="execute">
          <NFormItem label="Your Email" path="email" v-bind="formItemProp('email')">
            <NInput v-model:value="model.email" :input-props="{ type: 'email' }" @keydown.enter.prevent/>
          </NFormItem>

          <NButton :loading="isPending" attr-type="submit" block round size="medium" strong type="info">
            <span>Forgot Password</span>
          </NButton>
        </NForm>
      </div>
      <div class="flex justify-end px-6 py-6">
        <RouterButton
          :to="{name: 'Login'}"
          size="small" style="--n-text-color: #64748b;" tag="a"
          text
        >
          <span>Back to login</span>
        </RouterButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import {CheckedCircleFilledIcon} from '@core/components/icons.js';
import Logo from '@core/components/Logo.vue';
import RouterButton from '@core/components/route/RouterButton.vue';
import {useFormRequest} from '@core/composable/useRequest.ts';
import {webRequest} from '@core/http.ts';
import {NCollapseTransition, NButton, NForm, NFormItem, NInput} from 'naive-ui';
import {nextTick, ref} from 'vue';

const model = ref({email: ''});
const message = ref();
const showMessage = ref(false);

const {isPending, execute, formItemProp} = useFormRequest({
  request: webRequest,
  url: '/forgot-password',
  data: () => model.value,
  defaultMessages: {
    success: undefined,
    failed: 'Error encountered while executing request.',
  },
  postSuccess(response) {
    model.value.email = '';
    if (response?.data?.message) {
      message.value = response.data.message;
      nextTick(() => {
        showMessage.value = true;
      });
    }
  },
});
</script>

