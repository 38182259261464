<template>
  <div>
    <NForm class="flex flex-col space-y-3">
      <FormItem label="New password" :feedback="errors.password" :rule="Request.password">
        <NInput :readonly="!focused" v-model:value="model.password" type="password" @focus="focused=true"/>
      </FormItem>

      <FormItem label="Confirm Password" :feedback="errors.password_confirmation" :rule="Request.password_confirmation">
        <NInput :readonly="!focused" v-model:value="model.password_confirmation" type="password" @focus="focused=true"/>
      </FormItem>

      <NButton block round size="medium" type="info" @click="save" :loading="saving">Reset Password</NButton>
    </NForm>

    <div class="flex justify-end px-6 py-6">
      <RouterButton
          :to="{name: 'Login'}"
          size="small" style="--n-text-color: #64748b;" tag="a"
          text
      >
        <span>Back to login</span>
      </RouterButton>
    </div>
  </div>
</template>

<script setup lang="tsx">
import {ResetPasswordRequest as Request} from "@admin/types/generated.ts";
import FormItem from "@core/components/form/FormItem.vue";
import RouterButton from "@core/components/route/RouterButton.vue";
import useFormContext, {events} from "@core/composable/useFormContext.ts";
import axios from 'axios';
import {NButton, NForm, NInput} from "naive-ui";
import {ref} from "vue";
import {useRoute} from "vue-router";

interface IModel {
  password: string,
  password_confirmation: string,
}

const focused = ref(false);
const emit = defineEmits(events);
const route = useRoute();
const saveRequest = (payload) => axios.put('/password', payload, {headers: {'X-Requested-With': 'XMLHttpRequest'}});

function newModelInstance(): Partial<IModel> {
  return {
    password: '',
    password_confirmation: '',
  }
}

function saveInstance(instance: any): IModel {
  return {
    ...instance,
    token: route.params.token,
    email: route.query.email,
  }
}

const {
  save,
  saving,
  model,
  errors
} = useFormContext<Partial<IModel>, ToErrorType<typeof Request>>(ref<IModel>(), saveRequest, newModelInstance, emit, saveInstance);
</script>

<style lang="scss" scoped>
.es-bg-secondary {
  background-color: #f1f1f1;
}

.btn-primary-es {
  background: #3152a3;
  border-color: #3152a3;
}
</style>
