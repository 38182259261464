<template>
  <Logo :ratio="0.45" :version="2" class="w-full flex justify-center mb-3.5" color-blend/>

  <div class="flex flex-col bg-white rounded shadow-md" style="min-width: min(28rem, calc(100vw - 2rem))">
    <h1 class="text-xl font-semibold px-6 pt-6 pb-2 leading-tight tracking-tight md:text-2xl text-gray-900">Sign in to
      your account</h1>
    <div class="flex flex-col px-6 py-4 border-slate-200/50 border-b">
      <NForm :model="model" class="flex flex-col space-y-3" @submit.prevent.stop="execute()">
        <NFormItem label="Your Email" path="email" v-bind="formItemProp('email')">
          <NInput v-model:value="model.email" :input-props="{ type: 'email' }" @keydown.enter.prevent/>
        </NFormItem>

        <NFormItem first label="Password" path="password" v-bind="formItemProp('password')">
          <NInput v-model:value="model.password" type="password" @keydown.enter.prevent/>
        </NFormItem>

        <NFormItem :show-feedback="false" :show-label="false">
          <NCheckbox v-model:checked="model.remember">Remember Me</NCheckbox>
        </NFormItem>


        <NButton :loading="isPending" attr-type="submit" block round size="medium" strong type="info">
          <span>Login</span>
        </NButton>
      </NForm>
    </div>
    <div class="flex justify-end px-6 py-6">
      <RouterButton
        :to="{name: 'ForgotPassword'}"
        size="small" style="--n-text-color: #64748b;" tag="a"
        text
      >
        <span>Having trouble signing in?</span>
      </RouterButton>
    </div>
  </div>
</template>

<script setup>
import Logo from '@core/components/Logo.vue';
import RouterButton from '@core/components/route/RouterButton.vue';
import {useFormRequest} from '@core/composable/useRequest.ts';
import {webRequest} from '@core/http.ts';
import {NButton, NCheckbox, NForm, NFormItem, NInput} from 'naive-ui';
import {ref} from 'vue';
import {useRoute} from "vue-router";

const route = useRoute();

const model = ref({
  email: '',
  password: '',
  remember: false,
});

const {isPending, execute, formItemProp} = useFormRequest({
  method: 'POST',
  url: '/login',
  data: () => {
    return {...model.value, redirect: route.query.redirect}
  },
  defaultMessages: {
    success: 'Login Successful!',
    failed: 'Error encountered while logging in.',
  },
  postSuccess(response) {
    if (response.data.redirect) {
      window.location = response.data.redirect;
    }
  },
  request: webRequest
});
</script>

<style lang="scss" scoped>
.es-bg-secondary {
  background-color: #f1f1f1;
}

.btn-primary-es {
  background: #3152a3;
  border-color: #3152a3;
}
</style>
