import ForgotPassword from '@guest/components/ForgotPassword.vue';
import Login from '@guest/components/Login.vue';
import Register from '@guest/components/Register.vue';
import ResetPassword from '@guest/components/ResetPassword.vue';
import {createRouter, createWebHistory} from 'vue-router';

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: 'active',
  linkActiveClass: 'active',
  routes: [
    {
      path: '/guest/login',
      name: 'Login',
      component: Login,
    },
    {
      path: '/guest/register',
      name: 'Register',
      component: Register,
    },
    {
      path: '/guest/forgot-password',
      name: 'ForgotPassword',
      component: ForgotPassword,
    },
    {
      path: '/guest/reset-password/:token',
      name: 'ResetPassword',
      component: ResetPassword,
    },
    {
      path: '/guest/',
      redirect() {
        return {name: 'Login'};
      },
    },
  ],
});
export default router;
