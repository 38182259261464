import {AxiosError} from 'axios';
import {computed, ref, unref} from 'vue';

/**
 *
 * @param fields {(string|number)[]}
 */
const useValidationErrors = (fields: string | number []) => {
    const errors = ref<{[key:string|number]:any}>({});

    const setError = (key : any, message = null) => {
        if (!key) {
            return;
        }

        errors.value[key] = message ?? null;
    };

  const clearError = (key = null) => {
    if (key) {
      setError(key);
      return;
    }

    errors.value = {};
  };

  const fromAxiosError = (e : any) => {
    clearError();

    if (!(e instanceof AxiosError) || e?.response?.status !== 422) {
      return {};
    }

    try {
        const _errors: Record<string | number, any> = e?.response?.data?.errors ?? ({} as { [key: string|number]: any });

        for (const field of fields) {
            errors.value[field] = Array.isArray(_errors[field as unknown as keyof typeof _errors]) ? _errors[field][0] : _errors[field];
        }

        // tslint:disable-next-line:no-empty
    } catch {
    }
  };

    const formItemProp = computed(() => (path: any) => {
        const feedback = unref<any>(errors)[path];

        if (feedback) {
            return {
                path,
                validationStatus: 'error',
                feedback,
            };
        }

        return {
      path,
      showFeedback: false,
    };
  });

  return {
    errors,
    formItemProp,
    fromAxiosError,
    setError,
    clearError,
  };
};

export default useValidationErrors;
