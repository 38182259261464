import {createPinia} from 'pinia';
import {createApp} from 'vue';
import GuestApp from '@guest/GuestApp.vue';
import router from '@guest/router';

const meta = document.createElement('meta');
meta.name = 'naive-ui-style';
document.head.appendChild(meta);

const app = createApp(GuestApp);

document.body.classList.add('bg-gradient-to-br', 'from-slate-400');

app.use(router).
  use(createPinia()).
  mount('#guest-app');

